import { channelNames } from '@lib/variables'
import { isExternalURL } from '@lib/helpers'
import { Link } from '@routes'
import { Col, Container, Row } from '@styles/global.styled'
import * as S from './SuburbSEOLinks.styled'

const SuburbSEOLinks = ({ suburb, state, slug, linkGroups, propertyStats }) => {
  const getRouteName = (link) => {
    return link.criteria && link.criteria.categories
      ? 'suburbProperties'
      : link.criteria && (link.criteria.bedrooms || link.criteria.bedrooms__gte)
      ? 'suburbPropertiesBedrooms'
      : 'suburbProperties'
  }

  propertyStats.bedrooms_for_sale =
    propertyStats.bedrooms_1_for_sale +
    propertyStats.bedrooms_2_for_sale +
    propertyStats.bedrooms_3_for_sale +
    propertyStats.bedrooms_4_for_sale +
    propertyStats.bedrooms_5_plus_for_sale

  propertyStats.bedrooms_for_rent =
    propertyStats.bedrooms_1_for_rent +
    propertyStats.bedrooms_2_for_rent +
    propertyStats.bedrooms_3_for_rent +
    propertyStats.bedrooms_4_for_rent +
    propertyStats.bedrooms_5_plus_for_rent

  const getQueryParams = ({ link, slug, channel, state }) => {
    let params = {
      suburb: slug,
      state: state.toLowerCase(),
      channel: channel,
    }
    if (link && link.criteria && link.criteria.categories) {
      params.categories = link.criteria.categories
    }
    if (link && link.criteria && link.criteria.bedrooms) {
      params.bedrooms = link.criteria.bedrooms
    }
    return params
  }

  return suburb ? (
    <S.SuburbSEOLinks>
      <Container>
        <S.SuburbSEOLinksTitle>{suburb} quick links</S.SuburbSEOLinksTitle>
        {linkGroups?.length > 0 && (
          <Row gutter={15}>
            {linkGroups.map((group, index) => (
              <Col md={6} lg={3} key={index}>
                <S.SuburbSEOLinkGroup>
                  <S.SuburbSEOLinkGroupTitle>
                    {group.title}
                  </S.SuburbSEOLinkGroupTitle>
                  {group.links?.length &&
                    group.links.map((link, index) =>
                      propertyStats?.[link?.stats?.sale] > 0 ||
                      propertyStats?.[link?.stats?.lease] > 0 ? (
                        <S.SuburbSEOLinkGroupItem
                          key={`suburb-seo-link-${index}`}
                        >
                          {link.title}
                          {(link.type === 'sale' || link.type === 'both') &&
                          propertyStats?.[link?.stats?.sale] > 0 ? (
                            <>
                              &nbsp;-&nbsp;
                              <Link
                                route={getRouteName(link)}
                                params={getQueryParams({
                                  slug: slug,
                                  state: state.toLowerCase(),
                                  channel: channelNames.sale,
                                  link: link,
                                })}
                                passHref
                                target={
                                  isExternalURL(getRouteName(link))
                                    ? '_blank'
                                    : '_self'
                                }
                                key={`group-link-item-${index}`}
                              >
                                for Sale
                              </Link>
                            </>
                          ) : null}
                          {(link.type === 'lease' || link.type === 'both') &&
                          propertyStats?.[link?.stats?.lease] > 0 ? (
                            <>
                              &nbsp;-&nbsp;
                              <Link
                                route={getRouteName(link)}
                                params={getQueryParams({
                                  slug: slug,
                                  state: state.toLowerCase(),
                                  channel: channelNames.rent,
                                  link: link,
                                })}
                                passHref
                                target={
                                  isExternalURL(getRouteName(link))
                                    ? '_blank'
                                    : '_self'
                                }
                              >
                                for Rent
                              </Link>
                            </>
                          ) : null}
                        </S.SuburbSEOLinkGroupItem>
                      ) : null
                    )}
                </S.SuburbSEOLinkGroup>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </S.SuburbSEOLinks>
  ) : null
}

SuburbSEOLinks.defaultProps = {
  suburb: 'Coburg',
  slug: 'coburg',
  linkGroups: [
    {
      title: 'Residential Property',
      links: [
        {
          title: 'Acreage Property',
          type: 'sale',
          stats: {
            sale: 'AcreageSemi-rural_for_sale',
            lease: 'AcreageSemi-rural_for_rent',
          },
          criteria: {
            categories: 'acreages',
            property_class: {
              sale: 'residential',
            },
          },
        },
        {
          title: 'Alpine',
          type: 'both',
          stats: {
            sale: 'Alpine_for_sale',
            lease: 'Alpine_for_rent',
          },
          criteria: {
            categories: 'alpine',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Apartments',
          type: 'both',
          stats: {
            sale: 'Apartment_for_sale',
            lease: 'Apartment_for_rent',
          },
          criteria: {
            categories: 'apartments',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Apartment Blocks',
          stats: {
            sale: 'ServicedApartment_for_sale',
            lease: 'ServicedApartment_for_rent',
          },
          type: 'sale',
          criteria: {
            categories: 'apartment-blocks',
          },
        },
        {
          title: 'Block Of Units',
          type: 'both',
          stats: {
            sale: 'BlockOfUnits_for_sale',
            lease: 'BlockOfUnits_for_rent',
          },
          criteria: {
            categories: 'BlockOfUnits',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Duplex',
          type: 'both',
          stats: {
            sale: 'DuplexSemi-detached_for_sale',
            lease: 'DuplexSemi-detached_for_rent',
          },
          criteria: {
            categories: 'duplexes',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Flat',
          type: 'both',
          stats: {
            sale: 'Flat_for_sale',
            lease: 'Flat_for_rent',
          },
          criteria: {
            categories: 'flat',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'House',
          type: 'both',
          stats: {
            sale: 'House_for_sale',
            lease: 'House_for_rent',
          },
          criteria: {
            categories: 'houses',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'New Developments',
          type: 'sale',
          stats: {
            sale: 'new_development',
          },
          criteria: {
            categories: 'new-developments',
            property_class: {
              sale: 'residential',
            },
          },
        },
        {
          title: 'Retirement',
          type: 'both',
          stats: {
            sale: 'Retirement_for_sale',
            lease: 'Retirement_for_rent',
          },
          criteria: {
            categories: 'retirement',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Rural',
          type: 'both',
          stats: {
            sale: 'Rural_for_sale',
            lease: 'Rural_for_rent',
          },
          criteria: {
            categories: 'rural',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Studio Apartment',
          type: 'both',
          stats: {
            sale: 'Studio_for_sale',
            lease: 'Studio_for_rent',
          },
          criteria: {
            categories: 'studio',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Terrace',
          type: 'both',
          stats: {
            sale: 'Terrace_for_sale',
            lease: 'Terrace_for_rent',
          },
          criteria: {
            categories: 'terrace',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Townhouses',
          type: 'both',
          stats: {
            sale: 'Townhouse_for_sale',
            lease: 'Townhouse_for_rent',
          },
          criteria: {
            categories: 'townhouses',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Unit',
          type: 'both',
          stats: {
            sale: 'Unit_for_sale',
            lease: 'Unit_for_rent',
          },
          criteria: {
            categories: 'unit',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Vacant Land',
          type: 'sale',
          stats: {
            sale: 'Land_for_sale',
            lease: 'Land_for_rent',
          },
          criteria: {
            categories: 'land',
            property_class: {
              sale: 'land',
            },
          },
        },
        {
          title: 'Villas',
          type: 'both',
          stats: {
            sale: 'Villa_for_sale',
            lease: 'Villa_for_rent',
          },
          criteria: {
            categories: 'villas',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Warehouse',
          type: 'both',
          stats: {
            sale: 'Warehouse_for_sale',
            lease: 'Warehouse_for_rent',
          },
          criteria: {
            categories: 'warehouse',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Other',
          type: 'both',
          stats: {
            sale: 'Other_for_sale',
            lease: 'Other_for_rent',
          },
          criteria: {
            categories: 'other',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
      ],
    },
    {
      title: 'Find Properties by Bedrooms',
      links: [
        {
          title: 'All bedrooms',
          type: 'both',
          stats: {
            sale: 'bedrooms_for_sale',
            lease: 'bedrooms_for_rent',
          },
          criteria: {
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: '1 bedroom',
          type: 'both',
          stats: {
            sale: 'bedrooms_1_for_sale',
            lease: 'bedrooms_1_for_rent',
          },
          criteria: {
            bedrooms: 1,
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: '2 bedrooms',
          type: 'both',
          stats: {
            sale: 'bedrooms_2_for_sale',
            lease: 'bedrooms_2_for_rent',
          },
          criteria: {
            bedrooms: 2,
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: '3 bedrooms',
          type: 'both',
          stats: {
            sale: 'bedrooms_3_for_sale',
            lease: 'bedrooms_3_for_rent',
          },
          criteria: {
            bedrooms: 3,
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: '4 bedrooms',
          type: 'both',
          stats: {
            sale: 'bedrooms_4_for_sale',
            lease: 'bedrooms_4_for_rent',
          },
          criteria: {
            bedrooms: 4,
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: '5+ bedrooms',
          type: 'both',
          stats: {
            sale: 'bedrooms_5_plus_for_sale',
            lease: 'bedrooms_5_plus_for_rent',
          },
          criteria: {
            bedrooms: 5,
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
      ],
    },
  ],
}

export default SuburbSEOLinks
